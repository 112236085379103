@font-face {
  font-family: Roboto;
  src: url("Roboto-Black.be41af2a.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Bold.fdb9b54a.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Italic.5abfa3f1.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Light.1695f332.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-LightItalic.50b96eae.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Medium.91322885.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.ca197847.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Thin.b913caf8.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.pointer-events-none {
  pointer-events: none;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.isolate {
  isolation: isolate;
}

.z-\[10\] {
  z-index: 10;
}

.z-\[1\] {
  z-index: 1;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.contents {
  display: contents;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[80px\] {
  height: 80px;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[900px\] {
  width: 900px;
}

.w-full {
  width: 100%;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.scale-\[1\.57\] {
  --tw-scale-x: 1.57;
  --tw-scale-y: 1.57;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-full {
  border-radius: 9999px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#A17FFF\] {
  --tw-text-opacity: 1;
  color: rgb(161 127 255 / var(--tw-text-opacity));
}

.text-\[\#DACCFF\] {
  --tw-text-opacity: 1;
  color: rgb(218 204 255 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

:root {
  --gradient-dark: linear-gradient(128deg, #33001b 0%, #0e0033 100%);
  --gradient-dark-2: linear-gradient(128deg, #660036 0%, #220080 100%);
  --gradient: linear-gradient(128deg, #f08 0%, #40f 100%);
  --gradient-10-percent: linear-gradient(128deg, #ff00881a 0%, #4400ff1a 100%);
  --gradient-light: linear-gradient(128deg, #ff80c4 0%, #a280ff 100%);
  --gradient-light-50-percent: linear-gradient(128deg, #ff80c480 0%, #a280ff80 100%);
  --gradient-light-2: linear-gradient(128deg, #ffcce7 0%, #daccff 100%);
  --shade: linear-gradient(0deg, #0000004d 0%, #ffffff4d 100%);
}

* {
  font-family: Roboto;
}

::selection {
  color: #fff;
  background: #fff2;
}

body {
  background: #17141f;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

header {
  background: var(--gradient-dark);
  border-image: var(--gradient-light-50-percent) 30;
  border-style: solid;
  border-bottom-width: 1px;
}

footer {
  background: var(--gradient-dark);
  border-image: var(--gradient-light-50-percent) 30;
  border-style: solid;
  border-top-width: 1px;
}

#logo, .text-gradient {
  background-image: var(--gradient);
  color: #0000;
  background-clip: text;
}

.outline-gradient {
  display: inline-block;
  position: relative;
}

.outline-gradient:before {
  content: "";
  background: var(--gradient);
  z-index: -1;
  border-radius: inherit;
  background-clip: border-box;
  position: absolute;
  inset: -2px;
}

.outline-gradient-light {
  display: inline-block;
  position: relative;
}

.outline-gradient-light:before {
  content: "";
  background: var(--gradient-light);
  z-index: -1;
  border-radius: inherit;
  background-clip: border-box;
  position: absolute;
  inset: -2px;
}

.text-gradient-light {
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
}

.text-gradient-light-2 {
  background-image: var(--gradient-light-2);
  color: #0000;
  background-clip: text;
}

.bg-gradient-dark {
  background: var(--gradient-dark);
}

.bg-gradient-dark-2 {
  background: var(--gradient-dark-2);
}

.bg-gradient {
  background: var(--gradient);
}

.bg-gradient-shaded {
  background: var(--shade), var(--gradient);
}

.steps-num {
  text-align: right;
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
  font-size: 8rem;
  font-weight: 700;
  line-height: .85;
}

.steps-title {
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.steps-text {
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
  font-size: 1rem;
  line-height: 1.5rem;
}

.steps-content {
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.steps-elem {
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  gap: 1rem;
  display: flex;
}

.faq-elem {
  flex-direction: column;
  gap: .5rem;
  width: 700px;
  display: flex;
}

.faq-elem h4 {
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.faq-elem p {
  background-image: var(--gradient-light);
  color: #0000;
  background-clip: text;
  font-size: 1rem;
  line-height: 1.5rem;
}

#privacy * {
  color: #ffcce7;
}

.hover\:drop-shadow-\[0_5px_10px_rgba\(0\,0\,0\,0\.75\)\]:hover {
  --tw-drop-shadow: drop-shadow(0 5px 10px #000000bf);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
/*# sourceMappingURL=index.94f3b3ea.css.map */
