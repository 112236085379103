@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/* @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
} */

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
} */

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
} */

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} */



@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
    --gradient-dark: linear-gradient(128deg, #33001b 0%, #0e0033 100%);
    --gradient-dark-2: linear-gradient(128deg, #660036 0%, #220080 100%);
    --gradient: linear-gradient(128deg, #FF0088 0%, #4400FF 100%);
    --gradient-10-percent: linear-gradient(128deg, rgba(255, 0, 136, 10%) 0%, rgba(68, 0, 255, 10%) 100%);
    --gradient-light: linear-gradient(128deg, #FF80C4 0%, #A280FF 100%);
    --gradient-light-50-percent: linear-gradient(128deg, rgba(255, 128, 196, 50%) 0%, rgba(162, 128, 255, 50%) 100%);
    --gradient-light-2: linear-gradient(128deg, #FFCCE7 0%, #DACCFF 100%);
    --shade: linear-gradient(0deg, rgba(0,0,0,.3) 0%, rgba(255,255,255,.3) 100%);
}


* {
    @apply font-[Roboto];
}


/* text mark color */
*::selection {
    background: #fff2;
    color: white;
}


body {
    background: hsl(258, 20%, 10%);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

header {
    background: var(--gradient-dark);
    border-image: var(--gradient-light-50-percent) 30;
    border-bottom-width: 1px;
    border-style: solid;
}

footer {
    background: var(--gradient-dark);
    border-image: var(--gradient-light-50-percent) 30;
    border-top-width: 1px;
    border-style: solid;
}

#logo {
    background-image: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.text-gradient {
    background-image: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.outline-gradient {
    position: relative;
    display: inline-block;
}

.outline-gradient::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: var(--gradient);
    z-index: -1;
    background-clip: border-box;
    border-radius: inherit;
}

.outline-gradient-light {
    position: relative;
    display: inline-block;
}

.outline-gradient-light::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: var(--gradient-light);
    z-index: -1;
    background-clip: border-box;
    border-radius: inherit;
}


.text-gradient-light {
    background-image: var(--gradient-light);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.text-gradient-light-2 {
    background-image: var(--gradient-light-2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.bg-gradient-dark {
    background: var(--gradient-dark);
}

.bg-gradient-dark-2 {
    background: var(--gradient-dark-2);
}

.bg-gradient {
    background: var(--gradient);
}

.bg-gradient-shaded {
    background: var(--shade), var(--gradient);
}



/* Steps */

.steps-num {
    @apply text-gradient-light text-9xl font-bold leading-[0.85] text-right;
}

.steps-title {
    @apply text-gradient-light text-4xl font-bold;
}

.steps-text {
    @apply text-gradient-light text-base;
}

.steps-content {
    @apply flex flex-col items-start gap-2;
}

.steps-elem {
    @apply flex flex-row gap-4;
    flex-grow: 1;
    flex-basis: 0;
}


.faq-elem {
    @apply flex flex-col gap-2 w-[700px];
}

.faq-elem h4 {
    @apply text-gradient-light text-2xl font-bold;
}

.faq-elem p {
    @apply text-gradient-light text-base;
}



#privacy * {
    color: #FFCCE7;
}